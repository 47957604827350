@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("./normalize.css");
@import url("./common.css");
@import url("./navbar.css");
@import url("./header.css");

:root {
  --primary: #9b0047;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  scroll-behavior: smooth;
}

button {
  all: unset;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

form button,
.modal-container a {
  display: inline-block;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  margin-top: 1rem;
  background-color: var(--primary);
  color: #fff;
}
input,
textarea,
select {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px 12px 16px;
  gap: 8px;
  font-size: 0.75rem;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #666;
}

main {
  margin: auto;
  display: flex;
  overflow: auto;
  max-height: 100vh;
  outline: 1px dashed lightgray;
  flex: none;
  flex-flow: column nowrap;
  scroll-behavior: smooth;
}

/* Header */
header,
section,
footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  scroll-snap-align: center;
  flex: 1 0 100%;
}
section {
  padding: 2rem;
  padding-top: 120px;
  /* background: #f5deb3; */
}

h2 {
  font-size: 2.75rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem;
}

h2 span {
  color: var(--primary);
}
.intro-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  flex: 1 0 100%;
  max-width: 100%;
}
.header-content-container,
.header-image-container,
.about-image-container,
.about-content,
.contact-content,
.contact-image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  flex: 1 0 100%;
  max-width: 100%;
  padding-top: 120px;
}
.contact-content {
  color: #fff;
}
.contact-image-container img {
  padding: 2rem;
}
.header-content-container {
  background: #000;
  color: #fff;
  position: relative;
  padding-top: 120px;
  z-index: 0;
}

.header-content-container .header-content {
  max-width: 720px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 0;
  height: 100%;
}

.header-content-container h3 {
  font-size: 2.5rem;
  line-height: 1.125;
  margin-bottom: 1rem;
}

.header-content-container h3 + p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.header-content-container .input-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
}

.input-info {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.header-content-container .input-group {
  flex: 1 0 auto;
  max-width: 500px;
}
.header-content-container .input-group input {
  width: 100%;
  height: 48px;
}
.header-content-container button {
  flex: 1 0 120px;
  max-width: 120px;
  height: 48px;
  align-self: flex-start;
  background-color: var(--primary);
  color: #fff;
  box-sizing: border-box;
}

.header-image-container {
  background: var(--primary);
  padding: 3rem;
  padding-top: 120px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: auto;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* about */

.about-section p {
  font-size: 1.125rem;
  text-align: center;
}

/* Features */

.features-section,
.intro-section {
  background-color: #f5f5f5;
  gap: 2rem;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1140px;
  gap: 2.5rem;
  padding: 2rem 0;
}

.features-container > div {
  flex: 1 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}

.features-container h3 {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.5rem;
}

.feature-image-container {
  flex: 0 0 100px;
  padding: 1rem;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee7ea;
}

.feature-image-container img {
  max-width: 100px;
  width: 100%;
  height: 100%;
  max-height: 100px;
}

/* Comparison */

.comparison-section {
  align-items: center;
}

.comparisons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  row-gap: 5rem;
  padding: 3rem;
  max-width: 1140px;
}

.comparison {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 250px;
  background: #f7f7f7;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  flex: 1 0 100%;
  max-width: 100%;
  padding: 2rem;
  text-align: center;
}

.comparison .image-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: var(--primary);
}

.comparison h4 {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

/* Contact */

.contact-section {
  background-color: var(--primary);
  padding: 0;
}

form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  text-align: left;
}
.contact-content label {
  color: #666;
}
.contact-content button {
  background-color: var(--primary);
  color: #fff;
}

.contact-content form > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-content input,
.contact-content textarea {
  width: 100%;
}
footer {
  background: #000;
  padding: 1rem 2rem;
  color: #fff;
  scroll-snap-align: end;
}

footer > div {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  width: 100%;

  justify-content: center;
}

.social-icons {
  display: flex;
  gap: 0.5rem;
}
.social-icons a {
  color: white;
}
.social-icons a:hover {
  color: var(--primary);
}

@media (min-width: 768px) {
  body {
    font-size: 18px;
  }

  h2 {
    width: 100%;
  }
  .logo-container {
    padding: 2rem;
  }
  .logo-container img {
    margin: 0 auto 0 0;
    max-width: 250px;
  }

  .features-section h2 {
    margin: 0;
    text-align: center;
    align-self: flex-end;
  }
  .features-section h3 {
    font-size: 1.5rem;
  }
  .header-content-container,
  .header-image-container,
  .about-image-container,
  .about-content,
  .contact-content,
  .contact-image-container {
    flex: 1 0 50%;
    max-width: 50%;
    text-align: left;
    padding: 2rem;
    padding-top: 100px;
  }
  .features-container > div {
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    text-align: left;
  }

  .comparison {
    flex: 1 0 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
  }
  .contact-image-container {
    flex: 1 0 40%;
    max-width: 40%;
    padding: 1rem;
  }
  .contact-image-container img {
    padding: 0;
  }
  .contact-content {
    flex: 1 0 60%;
    max-width: 60%;
    padding: 1rem;
  }
  footer > div {
    justify-content: space-between;
  }
}
.header-text-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}
.header-text-content .social-icons,
.header-text-content .header-main {
  margin-top: auto;
}
.header-text-content .social-icons {
  gap: 1.25rem;
  font-size: 1.75rem;
}

@media (min-width: 1240px) {
  main {
    scroll-snap-type: y mandatory;
  }
  header,
  section {
    min-height: 100vh;
  }
  .header-content-container,
  .header-image-container,
  .about-image-container,
  .about-content,
  .contact-content,
  .contact-image-container {
    padding: 5rem;
  }
  .features-container > div {
    flex: 1 0 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
  }
  .contact-image-container,
  .contact-content {
    flex: 1 0 50%;
    max-width: 50%;
  }
  .contact-content form {
    margin-right: 3rem;
    padding: 2.5rem 2rem;
  }
}

.text-black *,
.typo-light .text-black,
.text-black:hover {
  color: #2c2c2c !important;
}

.text-light *,
.typo-light .text-light,
.text-light:hover {
  color: #e1f2fb !important;
}

.text-white,
.typo-light .text-white,
.text-white:hover {
  color: #ffffff !important;
}

/* Navbar */

.logo-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #000;
  font-size: 2rem;
  padding: 1rem;
}

.close-button {
  all: unset;
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 1rem;
  cursor: pointer;
  color: #fff;
}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  color: #fff;
}

.logo-container img {
  display: block;
  max-width: 150px;
  margin: auto;
}

.logo-container button {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}

.logo-container img + p {
  font-size: 1rem;
  margin-top: 0.5rem;
}

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
}

#fp-nav.right {
  right: 17px;
}

#fp-nav.left {
  left: 17px;
}

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}

.fp-slidesNav.bottom {
  bottom: 17px;
}

.fp-slidesNav.top {
  top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}

#fp-nav ul li .fp-tooltip.right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.left {
  left: 20px;
}

#fp-nav {
  margin-top: 0 !important;
  right: 0;
  top: 0;
}

#fp-nav ul li {
  width: inherit;
  height: inherit;
  margin: inherit;
}

#fp-nav ul li a {
  display: block;
  height: inherit;
  width: inherit;
}

#fp-nav ul li a span {
  border-radius: 0;
  position: absolute;
  z-index: 1;
  height: inherit;
  width: inherit;
  border: inherit;
  background: inherit;
  left: inherit;
  top: inherit;
  margin: inherit;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span {
  height: inherit;
  width: inherit;
  margin: inherit;
  border-radius: inherit;
}

#fp-nav a:hover {
  opacity: 1;
}

/*----------  DOT LINE  ----------*/
#fp-nav {
  position: fixed;
  top: 0;
  height: 100%;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#fp-nav > ul {
  padding: 30px 0px;
}

#fp-nav:before,
#fp-nav:after {
  content: "";
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

#fp-nav.right {
  right: 15px !important;
}

/*----------  DOT NAVIGATION  ----------*/
#fp-nav ul li {
  width: 40px;
  height: 15px;
  margin: 10px;
}

#fp-nav ul li a {
  display: block;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-start | flex-end | center | space-between | space-around */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* flex-start | flex-end | center | baseline | stretch */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#fp-nav ul li a span {
  position: absolute;
  z-index: 1;
  height: 2px;
  width: 2px;
  background: #fff;
  -webkit-transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
  transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a span,
#fp-nav ul li:hover a.active span {
  width: 30px;
  height: 2px;
  opacity: 1;
  margin: 0px 0 0 -14px;
}

/*----------  TOOLTIP  ----------*/
#fp-nav ul li .fp-tooltip.right {
  right: 58px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  width: 0;
  opacity: 0;
  -webkit-transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
  transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  width: auto;
  opacity: 1;
  -webkit-transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
  transition: all 1s cubic-bezier(0.435, 0.01, 0.135, 1);
}

@media (min-width: 768px) {
  .logo-container {
    padding: 2rem;
    background-color: transparent;
  }

  .logo-container img {
    margin: 0 auto 0 0;
    max-width: 250px;
  }
}

@media (min-width: 1240px) {
  .logo-container button {
    margin-right: 2rem;
  }

  #fp-nav {
    display: flex;
  }
}

#fp-nav.text-black ul li a span {
  background-color: #000;
}

#fp-nav.text-white ul li a span {
  background-color: #fff;
}

#fp-nav.text-white::before,
#fp-nav.text-white::after {
  border-color: rgba(255, 255, 255, 0.2);
}

#fp-nav.text-black::before,
#fp-nav.text-black::after {
  border-color: rgba(0, 0, 0, 0.2);
}

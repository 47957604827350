.modal-container {
  display: flex;
  flex-direction: column;
  z-index: 99999999;
  position: relative;
}
.modal-container > div {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}
